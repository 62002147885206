import React, { useEffect } from "react"

import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

import IconListItem from "./iconListItem"

const IconLists = props => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: "-100px 0px",
  })

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden")
  }, [controls, inView])

  const variants = {
    visible: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    hidden: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  }

  return (
    <motion.ul
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      className="flex flex-wrap items-center justify-center pt-8 lg:max-w-2xl overflow-hidden"
    >
      <IconListItem
        icon="award"
        title="Quantity Surveying"
        text="Managing all aspects of the building project"
      />
      <IconListItem
        icon="building"
        title="Project Management"
        text="Ensuring the project is completed on budget and within scope"
      />
      <IconListItem
        icon="document"
        title="Fund Advisory"
        text="Helping you improve their investment decisions and risk profile."
      />
      <IconListItem
        icon="services"
        title="Specialist Services"
        text="Whatever it is, we can help"
      />
    </motion.ul>
  )
}

export default IconLists
