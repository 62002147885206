import React, { useEffect } from "react"
import { Link } from "gatsby"

import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

import Img from "gatsby-image"

import Dash from "./dash"

function createMarkup(text) {
  return { __html: text }
}

const LargeTextBlock = props => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: "-100px 0px",
  })

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden")
  }, [controls, inView])

  const leftCol = {
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        // delayChildren: 0.1,
        when: "beforeChildren", // "afterChildren" "beforeChildren"
      },
    },
    hidden: {
      opacity: 0,
    },
  }
  const leftColVariants = {
    visible: {
      x: 0,
      opacity: 1,
    },
    hidden: {
      x: -30,
      opacity: 0,
    },
  }

  const rightCol = {
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        // delayChildren: 0.1,
        when: "beforeChildren", // "afterChildren" "beforeChildren"
      },
    },
    hidden: {
      opacity: 0,
    },
  }
  const rightColVariants = {
    visible: {
      x: 0,
      opacity: 1,
    },
    hidden: {
      x: 30,
      opacity: 0,
    },
  }

  return (
    <div className="inner mt-32 md:mt-40 overflow-x-hidden">
      <div className="flex flex-wrap -mx-8 lg:-mx-12">
        <div className="w-full lg:w-7/12 px-8 lg:px-12 relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 162.8 162.8"
            className="absolute top-0 right-0 mr-8 lg:mr-12 w-112 text-gray-100 opacity-25"
          >
            <path className="fill-current" d="M162.8 162.8L0 0h162.8z" />
          </svg>

          <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={leftCol}
          >
            <motion.div variants={leftColVariants}>
              {/* <Dash text="About us" /> */}
            </motion.div>

            <motion.h2
              variants={leftColVariants}
              className="text-34 max-w-4xl mb-20 lg:mb-32 relative z-10"
            >
              {props.title}
            </motion.h2>
            <motion.div variants={leftColVariants}>
              <Img
                fluid={props.imgLarge}
                className="relative z-10 mb-20 lg:mb-0"
              />
            </motion.div>
          </motion.div>
        </div>
        <motion.div
          className="w-full lg:w-5/12 px-8 lg:px-12 flex flex-col-reverse justify-center lg:flex-col"
          initial="hidden"
          animate={controls}
          variants={rightCol}
        >
          {props.imgSmall && (
            <motion.div variants={rightColVariants}>
              <Img
                fluid={props.imgSmall}
                className="hidden lg:block lg:mb-16"
              />
            </motion.div>
          )}
          <motion.div
            variants={rightColVariants}
            className="mb-24"
            dangerouslySetInnerHTML={createMarkup(props.text)}
          />
          <Link to="/contact" className="btn mb-8">
            Contact us now
          </Link>
        </motion.div>
      </div>
    </div>
  )
}

export default LargeTextBlock
