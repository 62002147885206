import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import HomeHero from "../components/heros/home"

import Icon from "../components/icon/index.js"
import LargeTextBlock from "../components/textBlocks/large"
import IconLists from "../components/lists/iconLists"
import Box from "../components/textBlocks/box"
import ProjectList from "../components/lists/projectList"
import Title from "../components/textBlocks/title"
import LogoCarousel from "../components/lists/logoCarousel"
import LogoList from "../components/lists/logoList"
import JobSection from "../components/heros/jobSection"

// import SEO from "../components/seo";

function IndexPage(props) {
  const logos = [props.data.rics, props.data.ssip, props.data.constructionline]

  return (
    <Layout>
      <div>
        <HomeHero img={props.data.imageOne.childImageSharp.fluid} />
        <JobSection />

        <LargeTextBlock
          title="With over 40 years experience we have you covered"
          text='<p class="mb-10">We are pragmatic and proactive and provide a hands-on approach throughout the building process from inception to completion with a relentless focus on quality</p><p class="mb-10">We operate through the UK and Europe and have built up robust local knowledge and relationships with key stakeholders in most regions.</p><p class="mb-10">We will contribute positively to your project success</p>'
          imgLarge={props.data.aboutOne.childImageSharp.fluid}
          imgSmall={props.data.aboutTwo.childImageSharp.fluid}
        />
        <div className="container mt-32 overflow-x-hidden md:mt-40">
          <div className="flex flex-wrap lg:-mx-20">
            <Box
              dash="What we offer"
              title="Decades of experience, at your service."
              text="With our decades of experience, Hawthorne Consulting can tailor the service we provide to suit your needs and objectives."
            />
            <div className="flex items-center w-full lg:w-2/6 lg:px-20">
              <IconLists />
            </div>
          </div>
        </div>
        <LogoList logos={logos} />
        <Title
          dash="Latest Projects"
          text="At Hawthorne Consulting we are continuously working on leading edge projects."
        />
        <div className="projects">
          <ProjectList projects={props.data.projects} />
        </div>
      </div>
      <LogoCarousel logos={props.data.logos} />
    </Layout>
  )
}

export default IndexPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  {
    logos: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { eq: "logos/carousel" }
      }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    imageOne: file(relativePath: { eq: "home-hero-new.jpg" }) {
      ...fluidImage
    }
    aboutOne: file(relativePath: { eq: "about-one.jpg" }) {
      ...fluidImage
    }
    aboutTwo: file(relativePath: { eq: "about-two.jpg" }) {
      ...fluidImage
    }
    homeProject: file(relativePath: { eq: "home-project.jpg" }) {
      ...fluidImage
    }

    rics: file(relativePath: { eq: "logos/rics.png" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    constructionline: file(relativePath: { eq: "logos/constructionline.png" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ssip: file(relativePath: { eq: "logos/ssip.png" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    projects: allSanityProject(
      filter: {
        _id: {
          in: [
            "2a0b46fc-6928-4afe-9b96-88a6f5860046"
            "0d684f87-1d0e-43a0-ad7f-fa0fda8bb13a"
            "3ec6e6dd-85d4-4b47-af86-d2d4874d20a9"
          ]
        }
      }
    ) {
      edges {
        node {
          title
          categories {
            title
          }
          _rawBody(resolveReferences: { maxDepth: 10 })
          mainImage {
            asset {
              fluid(maxWidth: 1000) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
    }
  }
`
