import React, { useEffect } from "react"

import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"
import Icon from "../icon/index.js"

const IconListItem = props => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: "-100px 0px",
  })

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden")
  }, [controls, inView])

  const variants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    hidden: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  }

  return (
    <motion.li
      ref={ref}
      variants={variants}
      initial="hidden"
      animate={controls}
      className="flex mb-12 max-w-xl flex-grow "
    >
      <div className="w-24 flex-shrink-0 mb-8 xs:mb-0 -mt-4">
        <Icon
          name={props.icon}
          className="text-blue-200 w- flex-shrink-0 mr-8"
        />
      </div>
      <span className="w-full xs:w-auto">
        <h5 className="text-20 mb-4">{props.title}</h5>
        {props.text}
      </span>
    </motion.li>
  )
}

export default IconListItem
