import React, { useEffect } from "react"
import { Link } from "gatsby"

import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

import Dash from "./dash"

const Box = props => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: "-100px 0px",
  })

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden")
  }, [controls, inView])

  const wrapper = {
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        // delayChildren: 0.1,
        when: "beforeChildren", // "afterChildren" "beforeChildren"
      },
    },
    hidden: {
      opacity: 0,
    },
  }
  const wrapperVariants = {
    visible: {
      x: 0,
      opacity: 1,
    },
    hidden: {
      x: -30,
      opacity: 0,
    },
  }

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={wrapper}
      className="w-full lg:w-4/6 mb-20 lg:mb-0 lg:-ml-64 px-16 lg:pr-20 lg:pl-116 py-24 lg:py-48 bg-gray-50 relative"
    >
      <motion.div variants={wrapperVariants}>
        <Dash text={props.dash} />
      </motion.div>

      <motion.h3
        variants={wrapperVariants}
        className="text-34 lg:max-w-2xl mb-12 lg:mb-20"
      >
        {props.title}
      </motion.h3>
      <motion.p variants={wrapperVariants} className="lg:max-w-2xl mb-16">
        {props.text}
      </motion.p>
      <motion.div variants={wrapperVariants}>
        {/* <Link to="/services" className="btn">
          Find out more about
        </Link> */}
      </motion.div>
    </motion.div>
  )
}

export default Box
