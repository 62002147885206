import React, { useEffect } from "react"

import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

import Dash from "./dash"

const Title = props => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: "-100px 0px",
  })

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden")
  }, [controls, inView])

  const text = {
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        // delayChildren: 0.1,
        when: "beforeChildren", // "afterChildren" "beforeChildren"
      },
    },
    hidden: {
      opacity: 0,
    },
  }
  const textVariants = {
    visible: {
      x: 0,
      opacity: 1,
    },
    hidden: {
      x: -30,
      opacity: 0,
    },
  }

  return (
    <div className="inner mt-32 md:mt-40 overflow-x-hidden">
      <div className="flex flex-wrap -mx-8">
        <motion.div
          className="w-full md:w-1/2 px-8 mb-16"
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={text}
        >
          <motion.div variants={textVariants}>
            <Dash text={props.dash} />
          </motion.div>
          <motion.h3
            variants={textVariants}
            className="text-34 max-w-2xl mb-12 md:mb-24"
          >
            {props.text}
          </motion.h3>
        </motion.div>
      </div>
    </div>
  )
}

export default Title
