import React, { useEffect } from "react"
import { Link } from "gatsby"

import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

import Img from "gatsby-image"

const HomeHero = props => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: "-100px 0px",
  })

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden")
    inView ? console.log("visible") : console.log("hidden")
  }, [controls, inView])

  const imageVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        stiffness: 50,
      },
    },
    hidden: {
      opacity: 0,
      x: 120,
    },
  }

  const textVariants = {
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        // delayChildren: 0.1,
        when: "beforeChildren", // "afterChildren" "beforeChildren"
      },
    },
    hidden: {
      opacity: 0,
    },
  }
  const textChildrenVariants = {
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        stiffness: 50,
      },
    },
    hidden: {
      x: -30,
      opacity: 0,
    },
  }

  return (
    <div className="container overflow-hidden">
      <div className={`flex flex-wrap md:-mx-8 lg:-mx-12`}>
        <div className="w-full md:w-1/2 md:px-8 lg:px-12 mb-12">
          <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={imageVariants}
          >
            <Img fluid={props.img} />
          </motion.div>
        </div>
        <div className="w-full md:w-1/2 md:px-8 lg:px-12 flex flex-col justify-center relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 162.8 162.8"
            className="absolute top-0 right-0 md:mr-8 w-64 text-gray-100 opacity-25"
          >
            {/* <path fill="#bfe0ef" d="M162.8 162.8L0 0h162.8z" opacity=".26" /> */}
            <path d="M162.8 162.8L0 0h162.8z" className="fill-current" />
          </svg>
          <motion.div
            initial="hidden"
            animate={controls}
            variants={textVariants}
          >
            {/* <motion.p
              variants={textChildrenVariants}
              className="text-blue-200 text-20 dash-right"
            >
              The Start
            </motion.p> */}
            <motion.h1
              variants={textChildrenVariants}
              className="flex flex-col mb-12 md:mb-16 lg:mb-24 text-50 lg:text-70 xl:text-80"
            >
              Decades of
              <span className="-mt-4 text-gray-100">experience</span>
            </motion.h1>
            <motion.p
              variants={textChildrenVariants}
              className="max-w-3xl mb-16 md:mb-20 lg:mb-32"
            >
              Hawthorne Consulting, are commercial building procurement and cost
              management specialists offering and delivering tailored
              procurement strategies to our local and international clients,
              based on solid deliverables.
            </motion.p>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default HomeHero
