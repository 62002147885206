import React from "react"
import jobAd from "../../../static/QuantitySurveyorJob.pdf"

/* This example requires Tailwind CSS v3.0+ */
export default function JobSection() {
  return (
    <div className="bg-gradient-to-r from-gray-400 via-gray-900 to-gray-600">
      <div className="my-24 bg-gray-900 opacity-75">
        <div className="container">
          <div className="relative isolate overflow-hidden py-24 md:w-1/2 md:pr-10 sm:py-32 text-left items-start">
            <h2 className="text-4xl font-bold tracking-tight text-white">
              Join our team today
            </h2>
            <p className="mt-10 text-lg leading-8 text-white">
              We are always keen to attract the best talent available. If you
              think you match that profile, and are possessed of a strong work
              ethic, please get in touch.
            </p>
            <div className="mt-20 flex items-left gap-x-6">
              <a
                href="/contact"
                className="rounded-md bg-white p-6 text-base font-semibold leading-7 text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Contact us
              </a>
              <a
                href={jobAd}
                className="text-base font-semibold leading-7 text-white p-6"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
