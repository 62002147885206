import React, { useEffect } from "react"

import Img from "gatsby-image"

import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  fragment SanityImage on SanityImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
      fluid(maxWidth: 1000) {
        ...GatsbySanityImageFluid
      }
    }
  }
`

const ProjectListItem = props => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: "-100px 0px",
  })

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden")
  }, [controls, inView])

  const variants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    hidden: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  }

  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial="hidden"
      animate={controls}
      className="inner mb-20 md:mb-40 flex flex-wrap"
    >
      <div className="w-full md:w-1/2">
        <Img fluid={props.project.node.mainImage.asset.fluid} />
      </div>
      <div className="w-full md:w-1/2 bg-gray-50 md:my-24 py-10 md:py-20 px-12 md:px-16 flex flex-col">
        {props.project.node.categories[0] && (
          <p className="text-blue-200 text-16 md:text-20 mb-4 md:mb-6 dash-right">
            {props.project.node.categories[0].title}
          </p>
        )}
        <h3 className="text-29 md:text-34 max-w-2xl mb-8 md:mb-24 leading-tight">
          {props.project.node.title}
        </h3>
        {props.project.node._rawBody[0] && (
          <div>{props.project.node._rawBody[0].children[0].text}</div>
        )}
      </div>
    </motion.div>
  )
}

export default ProjectListItem
